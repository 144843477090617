import { MetaProps } from '@/components/Meta';
import { useRouter } from 'next/router';

export function useMetaProps (props: Partial<MetaProps> = {}) {
  const router = useRouter();

  const title = props.title || 'Games | FREE Online Games & Download Games | Play Games on Shockwave.com';
  const description = props.description || 'Play over 1,800 free online games. Shockwave.com offers the best puzzle games, cooking games, dress up games, car racing games, and more. New games every day!';
  
  const metaProps: Partial<MetaProps> = {
    ...props,
    title: title,
    titleTag: title,
    description: description,
    openGraph: {
      ...(props.openGraph || {}),
      url: process.env.NEXT_PUBLIC_BASE_URL + router.asPath,
      description: props.description,
      image: props.openGraph?.image || 'https://content.shockwave.com/i/s_logo_whi_on_blue_100x120.png',
      'image:width': '120px',
      'image:height': '120px',
      'image:type': 'image/png',
    }
  };

  return metaProps;
}
